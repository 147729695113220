/* Shadows */
.shadow-top {
  box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-sm {
  box-shadow: 0px -6px 15px -5px rgba(115,115,115,0.4);
}
.shadow-right {
  box-shadow: 10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-bottom {
  box-shadow: 0 10px 20px -5px rgba(115,115,115,0.75);
}
.shadow-left {
  box-shadow: -10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-all {
  box-shadow: 0 0 20px rgba(115,115,115,0.75);
}
.shadow-top-right{
  box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
              10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-bottom{
  box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
              0 10px 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-left{
  box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
              -10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-bottom-right{
  box-shadow: 0 10px 20px -5px rgba(115,115,115,0.75),
              10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-left-right{
  box-shadow: -10px 0 20px -5px rgba(115,115,115,0.75),
              10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-bottom-left{
  box-shadow: 0 10px 20px -5px rgba(115,115,115,0.75),
              -10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-bottom-right{
  box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
              0 10px 20px -5px rgba(115,115,115,0.75),
              10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-bottom-left{
  box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
              0 10px 20px -5px rgba(115,115,115,0.75),
              -10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-inset {
  box-shadow: inset 0 0 20px rgba(115,115,115,0.75);
}